import React, { useEffect, useState } from 'react';
import runOneSignal from './runOneSignal';
import './App.css';
import logoImage from './assets/start-88-logo.png'
import bingoImage from './assets/bingo88.png'
import whatsappImage from './assets/zap.png'
import recoverAccessImage from './assets/recoverAccess.png'
import SvgComponent from './iconComponent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function App() {
  const [isSafari, setIsSafari] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [textInput, setTextInput] = useState('');

  useEffect(() => {
    runOneSignal()
  }, [])

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/Safari/i) && !userAgent.match(/Chrome/i)) {
      setIsSafari(true);
    }
  }, [])

  useEffect(() => {
    const linkKit = localStorage.getItem('linkKit');
    if (linkKit) {
      window.location.href = linkKit
    }
  }, [])

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleBeforeInstallPrompt = (event) => {
    // Prevent the default prompt
    event.preventDefault();
    // Stash the event so it can be triggered later
    setDeferredPrompt(event);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the installation prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the installation prompt');
        } else {
          console.log('User dismissed the installation prompt');
        }
        // Clear the deferredPrompt so it can't be used again
        setDeferredPrompt(null);
      });
    }
  };

  const handleRecoverAccessClick = () => {
    window.location.href = 'https://start88.net/kit/recovery'
  }

  const handleWhatsappSuport = () => {
    window.location.href = 'https://api.whatsapp.com/send?phone=5544991724265'
  }

  const handleSetLinkKit = () => {
    localStorage.setItem('linkKit', textInput);
    window.location.href = textInput
  }

  const buttonStyle = {
    marginTop: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    display: deferredPrompt ? 'block' : 'none',
    backgroundColor: '#f2f2f2',
    color: '#333',
    fontSize: '16px',
    fontFamily: 'Roboto, sans-serif',
    padding: '12px 20px',
    borderRadius: '6px',
    border: 'none',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    width: '25%'
  };

  return (
    <div className="container">
      <FontAwesomeIcon icon="fa-solid fa-arrow-up-from-bracket" style={{color: "#ffffff",}} />
      <div className="content">
        <img
          src={bingoImage}
          alt="Logo bingo"
          className='logoBingo'
        />
        <img
          src={logoImage}
          alt="Logo"
          className='logo'
        />
        <h1>Fique por dentro das nossas novidades</h1>
        <h2>Para uma melhor experiência, aceite as notificações e instale nosso aplicativo para receber notificações em tempo real.</h2>
      </div>
      <button onClick={handleInstallClick} style={buttonStyle}>
        Instalar
      </button>
      {isSafari && (
        <div className='tutorial'>
          <p>Clique no botão</p>
          <SvgComponent />
          <p>e depois clique em adicionar a tela de inicio</p>
        </div>
      )}
      <div className='button-bottom'>
      <button onClick={handleRecoverAccessClick} className='button-recover'>
        <img
          src={recoverAccessImage}
          alt="Logo"
          className='recover-access-image'
        />
      </button>
      <button onClick={handleWhatsappSuport} className='button-whats'>
        <img
          src={whatsappImage}
          alt="Logo"
          className='whatsapp-image'
        />
      </button>
      </div>
      <h2 style={{textAlign: 'center'}}>
        Após a instalação, Informe o link do seu kit abaixo:
        </h2>
      <div className="input-button-container">
        <input type="text" value={textInput} onChange={(e) => setTextInput(e.target.value)} placeholder="https://start88.net/kit/00000" />
        <button onClick={handleSetLinkKit}>Cadastrar</button>
      </div>
    </div>
  );
}

export default App;
